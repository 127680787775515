import { Routes, Route } from "react-router-dom";
import "./App.css";
import Privacy_Policy from "./pages/Privacy-Policy";
import Terms from "./pages/TermsOfService";
// import Home from "./pages/Home";
// import MembersLogin from './pages/MembersLogin';
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
// import PostForm from './pages/AddPost';
import AccountDeletion from "./pages/AccountDeletion";
import XampusId from "./pages/XampusId";
import AboutUs from "./pages/About-us";
import About from "./pages/About";
import JobHunt from "./pages/JobHunt";
import HrSignIn from "./pages/HR-Auth-Pages/SignIn/HR-Auth";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import AddPost from "./pages/AddPost/AddPost";
import AddPostDetails from "./pages/HR-Add-JobDetails/HR-Add-Post-Details";
import HrHomepage from "./pages/hr-Portal-Homepage/Homepage";
import Payment from "./components/hr-portal/payment/Payment";
import ResumeService1 from "./components/resume-services-v1/ResumeService";
import ResumeService2 from "./components/resume-services-v2/ResumeService2";
import ResumeService3 from "./components/resume-services-v3/ResumeService3";
import RNService1 from "./components/rn-services-v1/RNService1";
import RNService2 from "./components/rn-services-v2/RNService2";
import RNService3 from "./components/rn-services-v3/RNService3";
import RedesignedHome from "./pages/RedesignedHome";
import IphoneOffer from "./pages/IphoneOffer";
import Guidance1 from "./pages/Guidance/v1/Guidancev1";
import Guidance2 from "./pages/Guidance/v2/Guidance2";
import Guidance3 from "./pages/Guidance/v3/Guidance3";
import RedesignedAboutUs from "./pages/RedesignedAboutUs";
import UserPage from "./pages/User/UserPage";
import CommunityGuidelines from "./pages/CommunityGuidelines";
import ChildSafetyStandards from "./pages/ChildSafetyStandards";
import { IoSwapVertical } from "react-icons/io5";
import SwapStatus from "./components/SwapComponent";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<RedesignedHome />} />
        <Route path="/termsofservice" element={<Terms />} />
        <Route path="/privacypolicy" element={<Privacy_Policy />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        <Route path="/about-us" element={<RedesignedAboutUs />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/xampusid" element={<XampusId />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/register" element={<Register />} />
        <Route path="/jobhunt" element={<JobHunt />} />
        <Route path="/auth/hr-portal" element={<HrSignIn />} />
        <Route
          path="/auth/hr-portal/forgot-password"
          element={<ForgotPassword />}
        />
        <Route path="/hr-portal/add-post" element={<AddPost />} />
        <Route
          path="/hr-portal/add-post-details"
          element={<AddPostDetails />}
        />
        <Route path="/hr-portal/payment" element={<Payment />} />
        <Route path="/hr-portal/homepage" element={<HrHomepage />} />
        <Route path="/resume-service-1" element={<ResumeService1 />} />
        <Route path="/resume-service-2" element={<ResumeService2 />} />
        <Route path="/resume-service-3" element={<ResumeService3 />} />
        <Route path="/rn-service-1" element={<RNService1 />} />
        <Route path="/rn-service-2" element={<RNService2 />} />
        <Route path="/rn-service-3" element={<RNService3 />} />
        <Route path="/getOffer" element={<IphoneOffer />} />
        <Route path="/guidance-1" element={<Guidance1 />} />
        <Route path="/guidance-2" element={<Guidance2 />} />
        <Route path="/guidance-3" element={<Guidance3 />} />
        <Route path="/dummy-xampus-id" element={<UserPage />} />
        <Route path="/community-guidelines" element={<CommunityGuidelines />} />
        <Route
          path="/child-safety-standards"
          element={<ChildSafetyStandards />}
        />
        {/* <Route path='/members/login' element={<MembersLogin/>}/>
        <Route path='/add' element={<PostForm/>}/>*/}
        <Route path="/account-deletion" element={<AccountDeletion />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/swap" element={<SwapStatus />} />
      </Routes>
    </div>
  );
}

export default App;
